<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <div class="container">
      <router-link to="/">
        <img src="assets/logo_branco_header.svg" class="navbar-brand" alt="DataSUAS"/>
      </router-link>
      <button @click="collapse = !collapse" class="navbar-toggler" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div :class="{ collapse }" class="navbar-collapse">
        <ul class="navbar-nav">
          <NavItem>
            <NavItemLink to="equipamentos" style="white-space: nowrap" text="Rede de proteção" />
          </NavItem>
          <NavItem>
            <NavItemLink to="painel-de-atendimentos-cras" text="Painel de Atendimentos CRAS" />
          </NavItem>
          <NavItem>
            <NavItemLink to="painel-de-atendimentos-creas" text="Painel de Atendimentos CREAS" />
          </NavItem>
          <NavItem>
            <a class="nav-link"  href="#sobre">
              Sobre o DataSUAS
            </a>
          </NavItem>
          <NavItem>
            <a href="https://conteudos.portabilis.com.br/data-suas-fale-conosco" target="_blank">
              <Button class="btn-secondary" style="white-space: nowrap" text="Fique por dentro"/>
            </a>
          </NavItem>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import NavItemLink from '../atoms/NavItemLink.vue'
import NavItem from '../atoms/NavItem.vue'
import Button from '../atoms/Button.vue'

export default {
  name: 'Navbar',
  components: {
    NavItemLink, NavItem, Button
  },
  data() {
    return {
      collapse: true,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import "../../sass/bootstrap";

.navbar-brand {
  font-family: $font-muli;
  font-weight: 800;
  font-size: 28px;
  line-height: 35px;
}

.navbar-nav {
  align-items: center;
}

.login .btn-primary {
  align-items: center;
  background-color: $white;
  color: $primary;
  border-radius: 6px;
  width: 122px;
}

</style>
