<template>
  <div class="tag-wrapper">
    <span
      class="badge badge-pill badge-primary tag"
      :class="bgColor">{{ text }} <x-icon size="12" @click="clear" class="close" stroke-width="4"></x-icon> </span>
  </div>
</template>

<script>
import { XIcon } from 'vue-feather-icons'

export default {
  name: 'Tag',
  components: {
    XIcon
  },
  methods: {
    clear() {
      this.$emit("close", null)
    }
  },
  props: ['text', 'bgColor'],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../sass/bootstrap";

.close {
  color: $white;
  margin-left: 10px;
  opacity: 1;
}

.tag {
  font-family: $font-muli;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;

  display: inline-block;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  padding-left: 10px;
  text-align: left;
}

.tag-wrapper {
  display: flex;
  align-items: center;
}
</style>
