export default Object.freeze({
  "CENTRO POP": {
    icon: "/assets/card_pop.svg",
    mapIcon: "/assets/pop_map.svg",
    key: "CENTRO POP",
    label: "CENTRO POP",
    bgColor: "bg-orange",
    description: 'É uma unidade pública estatal, de referência e atendimento especializado à população adulta em situação de rua, no âmbito da Proteção Social Especial de Média Complexidade do SUAS. Presta serviços especializados e continuados para pessoas que utilizam as ruas como espaço de moradia e/ou sobrevivência.',
  },
  "CRAS": {
    icon: "/assets/card_cras.svg",
    mapIcon: "/assets/cras_map.svg",
    key: "CRAS",
    label: "CRAS",
    bgColor: "bg-purple",
    description: 'O Centro de Referência de Assistência Social (Cras) é a porta de entrada do SUAS. É um local público, localizado prioritariamente em áreas de maior vulnerabilidade social, onde são oferecidos os serviços de Assistência Social, com o objetivo de fortalecer a convivência com a família e com a comunidade.',
  },
  "CREAS": {
    icon: "/assets/card_creas.svg",
    mapIcon: "/assets/creas_map.svg",
    key: "CREAS",
    label: "CREAS",
    bgColor: "bg-red",
    description: 'O Centro de Referência Especializado de Assistência Social (Creas) é uma unidade pública da política de Assistência Social onde são atendidas famílias e pessoas que estão em situação de risco social ou tiveram seus direitos violados.',
  },
  "ILPIs": {
    icon: "/assets/card_ilpi.svg",
    mapIcon: "/assets/ilpi_map.svg",
    key: "ILPIs",
    label: "ILPIs",
    bgColor: "bg-cyan",
    description: 'As ILPIs são locais destinados à moradia, permanente ou temporária, para pessoas com 60 anos ou mais. Segundo definição da Agência Nacional de Vigilância Sanitária, Anvisa, são instituições governamentais ou não-governamentais, de caráter residencial, destinadas a domicílio coletivo de idosos, com ou sem suporte familiar, em condição de liberdade, dignidade e cidadania.',
  },
  "Unidade de Acolhimento": {
    icon: "/assets/card_acolhedora.svg",
    mapIcon: "/assets/acolhedora_map.svg",
    key: "Unidade de Acolhimento",
    label: "Unidade de Acolhimento",
    bgColor: "bg-pink",
    description: 'As Unidades de Acolhimento são equipamentos socioassistenciais públicos ou privados que buscam assegurar a proteção integral a indivíduos ou famílias que se encontrem em situação de abandono, ameaça ou violação de direitos e que estejam afastados temporariamente de seu núcleo familiar ou comunitário. As Unidades de Acolhimento podem funcionar como Acolhimento Institucional, Abrigo, Casa-Lar, Casa de Passagem ou Residência Inclusiva. Há ainda as formas de República e de Família acolhedora.',
  },
  "Entidades da Assistência Social": {
    icon: "/assets/card_entidade_as.svg",
    mapIcon: "/assets/as_map.svg",
    key: "Entidades da Assistência Social",
    label: "Entidades da Assistência Social",
    bgColor: "bg-green",
    description: 'As entidades e organizações de assistência social são aquelas sem fins lucrativos e parceiras da administração pública no atendimento às famílias, indivíduos e grupos em situação de vulnerabilidade ou risco social e pessoal, que integram a rede socioassistencial junto aos entes federativos (órgãos gestores) e os conselhos de assistência social, formando o Sistema Único de Assistência Social.',
  },
  "Ações Coletivas": {
    icon: "/assets/card_coletiva.svg",
    mapIcon: "/assets/coletiva_map.svg",
    key: "Ações Coletivas",
    label: "Ações Coletivas",
    bgColor: "bg-indigo",
    description: 'A Ação Coletiva de solidariedade identifica iniciativas da sociedade civil que se agregam ao desafio de garantir a vida, de organizar ações e lutas para que a população vulnerável tenha assegurada condições dignas de viver neste período de pandemia.',
  },
});
