<template>
  <div class="mt-4">
    <HeadTitle text="Equipamentos e Entidades" class="mb-4" />
    <FilterCard :citiesOptions="citiesOptions" v-model="filter"/>
    <div class="row mt-4">
      <div
        class="col-12 col-lg-3">
        <div class="row">
          <div
            v-for="key in Object.keys(equipamentIcons)"
            :key="key.key"
            class="col-sm-6 col-md-4 col-lg-12">
            <EquipamentCard
              :equipamentTotal="results[key] && results[key].length || 0"
              :equipamentLabel="key"
              :equipamentIcon="equipamentIcons[key].icon"
              :equipamentDescription="equipamentIcons[key].description" />
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-9">
        <div class="card geo-map">
          <div class="geo-map-header">
            <h4>Mapa de georreferenciamento</h4>
          </div>
          <div class="map position-relative">
            <GoogleMap
              :config="config"
              :lat="lat"
              :lng="lng"
              :zoom="zoom"
              style="height: 890px">
              <template slot-scope="{ google, map }">
                <google-map-markers
                  :markers="equipamentsLocations"
                  :google="google"
                  @click="moreInfo"
                  :map="map">
                </google-map-markers>
              </template>
            </GoogleMap>
            <EquipamentInfo
              v-if="equipamentSelected"
              @close="equipamentSelected = null"
              :equipament="equipamentSelected.equipament" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../../http';
import HeadTitle from '../atoms/HeadTitle.vue'
import EquipamentInfo from '../atoms/EquipamentInfo.vue'
import GoogleMap from '../atoms/GoogleMap.vue'
import GoogleMapMarkers from '../atoms/GoogleMapMarkers.vue'
import EquipamentCard from '../molecules/EquipamentCard.vue'
import FilterCard from '../organisms/FilterCard.vue'
import EquipamentsIcons from '../utils/equipament_icons.js'

const _ = require('lodash');

export default {
  components: {
    HeadTitle, FilterCard, EquipamentCard, GoogleMap, GoogleMapMarkers, EquipamentInfo
  },
  data() {
    return {
      equipamentIcons: EquipamentsIcons,
      equipamentsLocations: [],
      equipamentSelected: null,
      filter: null,
      data: null,
      results: {},
      citiesOptions: [],
      lat: -27.151749,
      lng: -50.457139,
      zoom: 7.6,
      config: {
        clickableIcons: false,
        streetViewControl: false,
        panControlOptions: false,
        mapTypeControl: false,
        styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
          },
        ],
      },
    };
  },
  methods: {
    moreInfo(equipament) {
      this.equipamentSelected = equipament
    }
  },
  watch: {
    filter(value) {
      this.results = {}
      this.equipamentsLocations = []
      this.equipamentSelected = null

      if (value !== null) {
        const equipamentsLabel = _.map(value.equipaments, eq => eq.label)
        const citiesSelected = _.map(value.cities, c => c.label)

        const filtered = this.data.filter((data) =>
          _.includes(citiesSelected, data["Município"]) &&
          _.includes(equipamentsLabel, data["Tipo de Equipamento"])
        )

        this.results = _.groupBy(filtered, "Tipo de Equipamento")

        _.map(filtered, (equipament) => {
          return this.equipamentsLocations.push(
            {
              id: equipament["ID"],
              equipament: equipament,
              position: {
                lat: parseFloat(equipament["Lat"].replace(",", ".")),
                lng: parseFloat(equipament["Lng"].replace(",", "."))
              },
              config: {
                icon: {
                  url: this.equipamentIcons[equipament["Tipo de Equipamento"]].mapIcon,
                },
              },
            },
          )
        })
      }
    }
  },
  mounted() {
    document.title = 'DataSUAS | A maior plataforma de dados abertos da Assistência Social do Brasil';

    Api.getCities().then((cities) => {
      this.citiesOptions = cities
    });
    Api.getData().then((data) => {
      this.data = data
    });
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../sass/bootstrap";

.geo-map-header {
  display: flex;
}

.geo-map-header h4 {
  width: 100%;

  color: $blue;
  margin: 15px;

  font-family: $font-muli;
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
}

#headingOne1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#headingOne1 h4 {
  font-family: $font-muli;
}
</style>
