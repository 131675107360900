<template>
  <div class="mt-4">
    <HeadTitle text="Painel de Atendimentos - CREAS" class="mb-4" />
    <FilterCardCreas
      :cities="cities"
      :unities="unities"
      v-model="filter"
      class="mb-5" />
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <button @click="loadYearData(2017)" :class="{ active: year === 2017 }" type="button" class="nav-link">
              2017
            </button>
          </li>
          <li class="nav-item">
            <button @click="loadYearData(2018)" :class="{ active: year === 2018 }" type="button" class="nav-link">
              2018
            </button>
          </li>
          <li class="nav-item">
            <button @click="loadYearData(2019)" :class="{ active: year === 2019 }" type="button" class="nav-link">
              2019
            </button>
          </li>
          <li class="nav-item position-relative">
            <div class="info-2020"></div>
            <div class="info-2020-tooltip">
              Em breve serão exibidos os RMAs do ano de 2020 de municípios parceiros.
            </div>
            <button disabled type="button" class="nav-link">
              2020
            </button>
          </li>
        </ul>
        <div class="card card-info-group">
          <div v-if="filtered.length" class="card-body">
            <div v-for="(item, i) in creas" :key="i">
              <hr v-if="i > 0">
              <h3>
                {{ item.title }}
              </h3>
              <div v-for="(group, k) in item.groups" :key="k">
                <h4>
                  {{ group.subtitle }}
                </h4>
                <div class="row row-no-gutters align-items-stretch">
                  <template v-for="(item, j) in group.items">
                    <div v-if="item.subitems.length === 0" :key="j" class="col-sm-6 col-lg-3 mb-3 mb-lg-4">
                      <div class="text-white card-info bg-red">
                        <h5>
                          {{ filtered.reduce(((prev, current) => prev + current[item.key]), 0) }}
                          <small>
                            {{ item.title }}
                          </small>
                        </h5>
                        <p>
                          {{ item.subtitle }}
                        </p>
                      </div>
                    </div>
                    <div v-if="item.subitems.length" :key="j" class="col-12">
                      <div class="row row-no-gutters">
                        <div class="col-sm-6 col-lg-3 mb-3 mb-lg-4">
                          <div class="text-white card-info bg-red">
                            <h5>
                              {{ filtered.reduce(((prev, current) => prev + current[item.key]), 0) }}
                              <small>
                                {{ item.title }}
                              </small>
                            </h5>
                            <p>
                              {{ item.subtitle }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="row row-no-gutters">
                        <div v-for="(subitem, l) in item.subitems" :key="l" class="col-sm-6 col-lg-3 mb-3 mb-lg-4">
                          <div class="text-white card-info-small bg-red">
                            <div class="d-flex">
                              <h5 class="mr-2">
                                {{ filtered.reduce(((prev, current) => prev + current[subitem.key]), 0) }}
                              </h5>
                              <div style="line-height: 12px; padding-top: 12px">
                                <div><small>{{ subitem.title }}</small></div>
                                <div><small>{{ subitem.subtitle }}</small></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div v-if="filtered.length === 0" class="card-body p-5">
            <h3 class="text-center m-auto" style="max-width: 70%">
              Infelizmente o Município selecionado não possui dados para exibição até o momento.
              <img src="/assets/empty.svg" alt="" class="mt-5 w-100" />
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../../http';
import HeadTitle from 'atoms/HeadTitle.vue';
import FilterCardCreas from 'organisms/FilterCardCreas.vue';

export default {
  components: { FilterCardCreas, HeadTitle },
  computed: {
    filtered() {
      if (this.filter && this.filter.city && this.filter.items) {
        return this.data.filter(
          creas => creas.municipio === this.filter.city && this.filter.items.indexOf(creas.unidade) !== -1,
        );
      }

      return [];
    },
    unities() {
      const unities = {};

      this.data.forEach(creas => {
        if (!Array.isArray(unities[creas.municipio])) {
          unities[creas.municipio] = [];
        }

        unities[creas.municipio].push(creas.unidade);
      });

      return unities;
    },
  },
  methods: {
    loadCities() {
      Api.getCities().then(data => this.cities = data);
    },
    loadTitle() {
      Api.getCreasTitle().then(data => this.creas = data);
    },
    loadData(year) {
      Api.getCreasData(year).then(data => this.data = data);
    },
    loadYearData(year) {
      this.year = year;
      this.loadData(year);
    },
  },
  data() {
    return {
      filter: {
        state: null,
        city: null,
        items: [],
      },
      cities: [],
      creas: [],
      data: [],
      year: 2017,
    };
  },
  created() {
    document.title = 'Painel de Atendimentos - CREAS | DataSUAS';

    this.loadCities();
    this.loadTitle();
    this.loadData(this.year);
  },
};
</script>
