import Vue from 'vue'
import Router from 'vue-router'

import Cras from 'pages/Cras.vue'
import Creas from 'pages/Creas.vue'
import Equipment from 'pages/Equipment.vue'
import UseTerms from 'pages/UseTerms.vue'
import PrivacyPolice from 'pages/PrivacyPolice.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: [
    {
      name: 'equipaments',
      path: '/equipamentos',
      alias: '/',
      component: Equipment,
    },
    {
      name: 'terms_of_use',
      path: '/termos-de-uso',
      component: UseTerms,
    },
    {
      name: 'privacy_policy',
      path: '/politica-privacidade',
      component: PrivacyPolice,
    },
    {
      name: 'painel-de-atendimentos-cras',
      path: '/painel-de-atendimentos-cras',
      alias: '/cras',
      component: Cras,
    },
    {
      name: 'painel-de-atendimentos-creas',
      path: '/painel-de-atendimentos-creas',
      alias: '/creas',
      component: Creas,
    },
  ]
})
