<template>
  <div class="mt-4">
    <HeadTitle text="Painel de Atendimentos - CRAS" class="mb-4" />
    <FilterCardCras
      :cities="cities"
      :unities="unities"
      v-model="filter"
      class="mb-5" />
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <button @click="loadYearData(2017)" :class="{ active: year === 2017 }" type="button" class="nav-link">
              2017
            </button>
          </li>
          <li class="nav-item">
            <button @click="loadYearData(2018)" :class="{ active: year === 2018 }" type="button" class="nav-link">
              2018
            </button>
          </li>
          <li class="nav-item">
            <button @click="loadYearData(2019)" :class="{ active: year === 2019 }" type="button" class="nav-link">
              2019
            </button>
          </li>
          <li class="nav-item position-relative">
            <div class="info-2020"></div>
            <div class="info-2020-tooltip">
              Em breve serão exibidos os RMAs do ano de 2020 de municípios parceiros.
            </div>
            <button disabled type="button" class="nav-link">
              2020
            </button>
          </li>
        </ul>
        <div class="card card-info-group">
          <div v-if="filtered.length" class="card-body">
            <div v-for="(item, i) in cras" :key="i">
              <hr v-if="i > 0">
              <h3>
                {{ item.title }}
              </h3>
              <div v-for="(group, k) in item.groups" :key="k">
                <h4>
                  {{ group.subtitle }}
                </h4>
                <div class="row row-no-gutters align-items-stretch">
                  <div v-for="(item, j) in group.items" :key="j" class="col-sm-6 col-lg-3 mb-3 mb-lg-4">
                    <div class="text-white card-info bg-purple">
                      <div class="d-flex align-items-end">
                        <h5>
                          {{ filtered.reduce(((prev, current) => prev + current[item.key]), 0) }}
                        </h5>
                        <div class="card-info-title">
                          {{ item.title }}
                        </div>
                      </div>
                      <p>
                        {{ item.subtitle }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="filtered.length === 0" class="card-body p-5">
            <h3 class="text-center m-auto" style="max-width: 70%">
              Infelizmente o Município selecionado não possui dados para exibição até o momento.
              <img src="/assets/empty.svg" alt="" class="mt-5 w-100" />
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../../http';
import HeadTitle from 'atoms/HeadTitle.vue';
import FilterCardCras from 'organisms/FilterCardCras.vue';

export default {
  components: { FilterCardCras, HeadTitle },
  computed: {
    filtered() {
      if (this.filter && this.filter.city && this.filter.items) {
        return this.data.filter(
          cras => cras.municipio === this.filter.city && this.filter.items.indexOf(cras.unidade) !== -1
        );
      }

      return [];
    },
    unities() {
      const unities = {};

      this.data.forEach(cras => {
        if (!Array.isArray(unities[cras.municipio])) {
          unities[cras.municipio] = [];
        }

        unities[cras.municipio].push(cras.unidade);
      });

      return unities;
    }
  },
  methods: {
    loadCities() {
      Api.getCities().then(data => this.cities = data);
    },
    loadTitle() {
      Api.getCrasTitle().then(data => this.cras = data);
    },
    loadData(year) {
      Api.getCrasData(year).then(data => this.data = data);
    },
    loadYearData(year) {
      this.year = year;
      this.loadData(year);
    },
  },
  data() {
    return {
      filter: {
        state: null,
        city: null,
        items: [],
      },
      cities: [],
      cras: [],
      data: [],
      year: 2017,
    };
  },
  created() {
    document.title = 'Painel de Atendimentos - CRAS | DataSUAS';

    this.loadCities();
    this.loadTitle();
    this.loadData(this.year)
  },
};
</script>
