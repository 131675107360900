<template>
  <p class="label mb-2">{{ text }}</p>
</template>

<script>

export default {
  name: 'Label',
  props: ['text'],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../sass/bootstrap";

.label {
  font-family: $font-hind;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}
</style>
