<template>
  <router-link class="nav-link" :to="to">
    {{ text }}
  </router-link>
</template>

<script>
export default {
  name: 'NavItemLink',
  props: ['text', 'to']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../../sass/bootstrap";

.nav-link {
  font-family: $font-hind;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}
</style>
