<template>
  <div style="display: none">
    {{ markers}}
    <google-map-marker
      v-for="marker in markers"
      :key="marker.id"
      :google="google"
      :map="map"
      :marker="marker"
      @click="$emit('click', $event)">
      <slot :google="google" :map="map" :marker="marker"></slot>
    </google-map-marker>
  </div>
</template>

<script>
import GoogleMapMarker from './GoogleMapMarker.vue';
export default {
  components: { GoogleMapMarker },
  props: ['map', 'google', 'markers'],
  watch: {
    markers(newMarkers, oldMarkers) {
      const newIds = newMarkers.map(marker => marker.id);
      const oldIds = oldMarkers.map(marker => marker.id).filter(id => !newIds.includes(id));
      this.$children.forEach((component) => {
        if (oldIds.includes(component.marker.id)) {
          component.internalMarker.setVisible(false);
        }
        if (newIds.includes(component.marker.id)) {
          component.internalMarker.setVisible(true);
          component.internalMarker.setPosition(component.marker.position);
        }
      });
    },
  },
};
</script>
