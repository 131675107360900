<template>
  <hr class="line">
</template>

<script>

export default {
  name: 'LineSeparator'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../sass/bootstrap";

.line {
  border-color: $gray-200;
}

</style>
