<template>
  <div ref="options" class="position-relative">
    <h5 class="input-label">{{ label }}</h5>
    <input
      @blur="blur"
      :placeholder="selectedLabel || placeholder"
      :disabled="disabled"
      @focus="resetState"
      v-on="listeners"
      v-model="filter"
      type="text"
      class="form-control is-select select search-input" />
    <div v-if="open" class="form-control-list">
      <ul class="list-unstyled all-selection">
        <li class="border-bottom mb-3">
          <label class="mt-2 mb-3 ml-3">
            <input
              v-model="all"
              @change="allOptions"
              type="checkbox" />
            <span class="form-control-list-item d-inline">
              Todos
            </span>
          </label>
        </li>
          <li v-for="option in filtered" :key="option.key">
            <label class="m-0 selectedLabel">
              <input
                v-model="selected"
                :value="option"
                name="option"
                type="checkbox"
                class="form-control-list-input" />
              <span class="form-control-list-item">
                <slot :option="option">
                  <img :src="option.icon" alt="" class="mr-1"/>
                  {{ option.label }}
                </slot>
              </span>
            </label>
        </li>

        <li v-if="filtered.length === 0">
          <label class="m-0 d-block">
            <span class="form-control-list-item">
              Nenhum resultado encontrado
            </span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['options', 'value', 'label', 'placeholder', 'disabled'],
  components: {
  },
  computed: {
    filtered() {
      if (!this.filter) {
        return this.options;
      }

      return this.options.filter(option => option.label.slugify().includes(this.filter.slugify()));
    },
    listeners() {
      const events = {
        input() {
          return null;
        },
      };

      return Object.assign({}, this.$listeners, events);
    },
  },
  methods: {
    allOptions(event) {
      const checked = event.target.checked

      if (checked) {
        this.selected = this.options.map((option) => option)
        this.defineLabel(this.selected);
      } else {
        this.selected = []
      }
    },
    resetState() {
      this.open = true
      this.filter = null;
    },
    blur() {
      setTimeout(() => {
        this.defineLabel(this.value);
      }, 250);
    },
    defineLabel(value) {
      this.selectedLabel =  value.length + " " + "selecionados";
      this.filter = null;
    },
    onClick(event) {
      if (this.$refs.options.contains(event.target)) {
        return;
      }

      this.defineLabel(this.value);

      this.open = false;
    },
  },
  watch: {
    selected(value) {
      this.$emit('input', value);
    },
    value(value) {
      if (value.length !== this.options.length) {
        this.all = false
      }

      this.selected = value;
      this.defineLabel(value);
    },
  },
  data() {
    return {
      filter: null,
      open: false,
      selected: [],
      all: false,
      selectedLabel: null,
    };
  },
  mounted() {
    this.selected = this.value;

    window.addEventListener('click', this.onClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClick);
  },
};
</script>


<style scoped lang="scss">
@import "../../sass/bootstrap";

.close-button {
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 14px;
}

.close-button span {
  border: 0;
}

.input-label {
  color: $gray-300;
  font-family: $font-muli;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.search-input {
  font-family: $font-hind;
  border: 0;
  width: 315px;
}

.list-item {
  display: flex;
  align-items: center;
  border-top-left-radius: 1.09rem;
  border-bottom-left-radius: 1.09rem;
}

.list-item:hover {
  background-color: $white;
}

.bgSelected{
  background-color: $white;
}

.popIcon {
  background-color: #fd9e0059;
  border-radius: 50%;
  padding: 5px;
}

.form-control-list ul {
  background-color: $gray-100;
}

.selectedLabel {
  width: 100%;
}

.all-selection {
  padding-top: 0px;
}
</style>
