<template>
  <div style="display: none">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
    },
  },
  watch: {
    // eslint-disable-next-line
    'marker.position.lat': function () {
      this.internalMarker.setPosition(this.marker.position);
    },
    // eslint-disable-next-line
    'marker.position.lng': function () {
      this.internalMarker.setPosition(this.marker.position);
    },
  },
  data() {
    return {
      internalMarker: null,
    };
  },
  mounted() {
    const { Marker, InfoWindow } = this.google.maps;
    this.internalMarker = new Marker(Object.assign({
      position: this.marker.position,
      marker: this.marker,
      map: this.map,
    }, this.marker.config || {}));
    if (this.$el.innerHTML.length) {
      const infowindow = new InfoWindow({
        content: this.$el.innerHTML,
      });
      this.internalMarker.addListener('click', () => {
        infowindow.open(this.map, this.internalMarker);
      });
    }
    this.internalMarker.addListener('click', () => {
      this.$emit('click', this.marker);
    });
  },
};
</script>
