<template>
  <button class="btn">{{ text }}</button>
</template>

<script>
export default {
  name: 'Button',
  props: ['text'],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../sass/bootstrap";

</style>
