<template>
  <div id="app">
    <Main>
      <router-view />
    </Main>
  </div>
</template>

<script>
import Main from 'templates/Main.vue';

export default {
  components: { Main },
  data() {
    return {
      data: [],
      cities: [],
      equipaments: [],
    }
  },
}
</script>

<style lang="scss">
@import "/sass/bootstrap";

#app {
  background-color: $gray-100;
}
</style>
