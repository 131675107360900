<template>
  <div id="main">
    <Navbar />
    <div class="container">
      <slot></slot>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from '../molecules/Navbar.vue'
import Footer from '../molecules/Footer.vue'

export default {
  name: 'Main',
  components: {
    Navbar, Footer
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
