<template>
  <h3 class="head-title">{{ text }}</h3>
</template>

<script>

export default {
  name: 'HeadTitle',
  props: ['text'],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../sass/bootstrap";

.head-title {
  font-family: $font-muli;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  color: $black;
}
</style>
