<template>
  <div class="card mb-4">
    <div class="equipament-card position-relative">
      <div>
        <h2 class="equipaments-total">{{ equipamentTotal }}</h2>
        <FilterHeaderTitle :text="equipamentLabel" style="font-size: 12px;" />
      </div>
      <div class="equipament-icon">
        <img :src="equipamentIcon" alt="" class="mr-1"/>
      </div>
      <div class="info-2020-tooltip">
        {{ equipamentDescription }}
      </div>
    </div>
  </div>
</template>

<script>
import FilterHeaderTitle from '../atoms/FilterHeaderTitle.vue'

export default {
  props: ['citiesCount', 'equipamentTotal', 'equipamentLabel', 'equipamentIcon', 'equipamentDescription'],
  name: 'EquipamentCard',
  components: {
    FilterHeaderTitle
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import "../../sass/bootstrap";

.equipament-card {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.equipaments-total {
  font-family: $font-muli;
  font-weight: bold;
  font-size: 38px;
  line-height: 48px;

  color: $gray-400
}

.equipament-card span {
  font-family: $font-hind;
  font-size: 12px;
  line-height: 14px;
  color: $gray-300;
  display: block;
}
</style>
