<template>
  <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
    <div class="card">
      <div class="card-header bg-transparent" role="tab" id="headingOne">
        <div class="filter-header">
          <h4 class="mr-4">Filtros</h4>
          <div class="ml-4 mr-4" v-if="state.selected && !isOpen" style="min-width: 120px;">
            <FilterHeaderTitle text="Estado" class="ml-3"/>
            <Tag :text="state.selected" @close="resetState" bgColor="bg-blue" />
          </div>
          <div class="pl-4 border-left" v-if="cities && !isOpen">
            <FilterHeaderTitle text="Município" class="ml-3"/>
            <div class="tag-wrapper">
              <Tag
                v-for="(city, i) in displayedCities"
                :key="city.key"
                @close="removeCity(i)"
                :text="city.label"
                class="mr-2 mb-2"
                bgColor="bg-blue"/>
              <Tag
                v-if="citiesLength"
                :text="`+ ${citiesLength}`"
                class="mr-2 mb-2"
                bgColor="bg-blue"/>
            </div>
          </div>
        </div>
        <a data-toggle="collapse"
           data-parent="#accordionEx"
           v-on:click="isOpen = !isOpen"
           aria-expanded="true"
           class="arrow-toggle"
           aria-controls="collapseOne">
          <i v-bind:class="isOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
        </a>
      </div>

      <div id="collapseOne"
           v-bind:class="{show: isOpen}"
           class="collapse"
           role="tabpanel"
           aria-labelledby="headingOne"
           data-parent="#accordionEx">

        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
              <div>
                <Options
                  class="mb-4"
                  placeholder="Selecione um estado"
                  label="Estado"
                  :options="state.options"
                  v-model="state.selected" />
                <MultipleChoices class="mb-4" placeholder="Selecione 1 ou mais municípios"
                label="Município" :options="citiesOptions" v-model="cities" />
                <MultipleChoices class="mb-4" placeholder="Selecione 1 ou mais equipamentos"
                label="Tipo de equipamentos" :options="equipaments.options" v-model="equipaments.selected" />
              </div>
            </div>

            <div class="col-12 cold-md-6 col-lg-8">
              <div class="mb-4" v-if="state.selected">
                <Label text="Estado" />
                <Tag :text="state.selected" @close="resetState" bgColor="bg-blue" />
              </div>
              <div class="mt-4 pt-2" v-if="cities.length > 0">
                <Label text="Municípios" />
                  <div class="tag-wrapper">
                    <Tag
                      v-for="(city, i) in displayedCities"
                      :key="city.key"
                      @close="removeCity(i)"
                      :text="city.label"
                      class="mr-2 mb-2"
                      bgColor="bg-blue"/>
                    <Tag
                      v-if="citiesLength"
                      :text="`+ ${citiesLength}`"
                      class="mr-2 mb-2"
                      bgColor="bg-blue"/>
                  </div>
              </div>
              <div class="mt-4 pt-2" v-if="equipaments.selected.length > 0">
                <Label text="Tipos de equipamentos" />
                  <div class="tag-wrapper">
                    <Tag
                      v-for="(equipament, i) in equipaments.selected"
                      :key="equipament.key"
                      @close="removeEquipament(i)"
                      :text="equipament.label"
                      class="mr-2 mb-2"
                      :bgColor="equipament.bgColor"/>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <LineSeparator />
        <div class="button-wrapper">
          <Button class="btn-primary ml-2" v-on:click.native="applyFilter" text="Aplicar filtro" :disabled="!canApplyFilter" />
          <Button class="btn-secondary mr-2"  v-on:click.native="resetFilter" text="Limpar filtro" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Options from '../atoms/Options.vue'
import MultipleChoices from '../atoms/MultipleChoices.vue'
import Tag from '../atoms/Tag.vue'
import Label from '../atoms/Label.vue'
import LineSeparator from '../atoms/LineSeparator.vue'
import Button from '../atoms/Button.vue'
import FilterHeaderTitle from '../atoms/FilterHeaderTitle.vue'
import EquipamentsIcons from '../utils/equipament_icons.js'

export default {
  props: ['citiesOptions', 'data'],
  name: 'FilterCard',
  components: {
    Options, Tag, Label, MultipleChoices, LineSeparator, Button, FilterHeaderTitle
  },
  computed: {
    canApplyFilter() {
      return this.state.selected && this.cities.length && this.equipaments.selected.length > 0;
    },
    displayedCities() {
      return this.cities.slice(0, 10)
    },
    citiesLength() {
      return this.cities.length - this.displayedCities.length
    },
  },
  methods: {
    removeCity(index) {
      this.cities.splice(index, 1)
      this.applyFilter(null, true)
    },
    removeEquipament(index) {
      this.equipaments.selected.splice(index, 1)
      this.applyFilter(null, true)
    },
    resetState() {
      this.state.selected = null
      this.cities = []
      this.$emit("input", null);
      this.isOpen = true
    },
    resetFilter() {
      this.state.selected = null
      this.cities = []
      this.equipaments.selected = []
      this.$emit("input", null);
    },
    applyFilter(event, isOpen = false) {
      this.filter = {
        "state": this.state.selected,
        "cities": this.cities,
        "equipaments": this.equipaments.selected
      }

      this.isOpen = isOpen;
      this.$emit("input", this.filter);
    }
  },
  data() {
    return {
      isOpen: true,
      equipamentIcons: EquipamentsIcons,
      state: {
        options: [
          {key: "Santa Catarina", label: "Santa Catarina"}
        ],
        selected: null,
      },
      cities: [],
      filter: {},
      equipaments: {
        options: Object.values(EquipamentsIcons),
        selected: []
      }
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../sass/bootstrap";

#headingOne {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#headingOne h4 {
  font-family: $font-muli;
}

#headingOne i {
  font-size: 13px;
}

.accordion .card {
  overflow: initial;
}

.arrow-toggle {
  color: $blue;
}

.tag-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.button-wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px;
}

.filter-header {
  display: flex;
  align-items: center;
}
</style>
