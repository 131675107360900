<template>
  <h5 class="input-label">{{ text }}</h5>
</template>

<script>

export default {
  name: 'FilterHeaderTitle',
  props: ['text'],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../sass/bootstrap";

.input-label {
  color: $gray-400;
  font-family: $font-muli;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
</style>
