<template>
  <div class="google-map">
    <div class="google-map-container" ref="map"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map"></slot>
    </template>
  </div>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader';
export default {
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    lat: {
      type: Number,
      default: 0,
    },
    lng: {
      type: Number,
      default: 0,
    },
    zoom: {
      type: Number,
      default: 10,
    },
  },
  methods: {
    initialize() {
      const config = Object.assign(this.config, {
        center: {
          lat: this.lat,
          lng: this.lng,
        },
        zoom: this.zoom,
      });
      this.map = new this.google.maps.Map(
        this.$refs.map, config,
      );
    },
    setCenter() {
      this.map.setCenter({
        lat: this.lat,
        lng: this.lng,
      });
    },
  },
  watch: {
    lat() {
      this.setCenter();
    },
    lng() {
      this.setCenter();
    },
  },
  data() {
    return {
      google: null,
      map: null,
    };
  },
  async mounted() {
    this.google = await GoogleMapsApiLoader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    });
    this.initialize();
  },
};
</script>

<style scoped>
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.google-map {
  position: relative;
}
.google-map::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 1px solid #ccc;
  border-top-color: #07d;
  animation: spinner .6s linear infinite;
}
.google-map-container {
  min-height: 100%;
  width: 100%;
}
</style>
