<template>
  <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
    <div class="card">
      <div class="card-header bg-transparent" role="tab" id="headingOne">
        <div class="filter-header">
          <h4 class="mr-4">Filtros</h4>
          <div class="ml-4 mr-4" v-if="state.selected && !isOpen">
            <FilterHeaderTitle text="Estado" class="ml-3"/>
            <Tag :text="state.selected" @close="resetState" bgColor="bg-blue" />
          </div>
          <div class="pl-4 border-left" v-if="city.selected && !isOpen">
            <FilterHeaderTitle text="Município" class="ml-3"/>
            <Tag :text="city.selected" @close="resetCity" bgColor="bg-blue" />
          </div>
        </div>
        <a data-toggle="collapse"
           data-parent="#accordionEx"
           v-on:click="isOpen = !isOpen"
           aria-expanded="true"
           class="arrow-toggle"
           aria-controls="collapseOne">
          <i v-bind:class="isOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
        </a>
      </div>

      <div id="collapseOne"
           v-bind:class="{show: isOpen}"
           class="collapse"
           role="tabpanel"
           aria-labelledby="headingOne"
           data-parent="#accordionEx">

        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
              <div>
                <Options
                  v-model="state.selected"
                  @input="resetCity"
                  :options="state.options"
                  class="mb-4"
                  placeholder="Selecione um estado"
                  label="Estado" />
                <Options
                  v-model="city.selected"
                  @input="resetCras"
                  :options="cities"
                  :disabled="state.selected === null"
                  class="mb-4"
                  placeholder="Selecione um município"
                  label="Município"  />
                <MultipleChoices
                  v-model="items.selected"
                  :options="unities[city.selected] || []"
                  :disabled="city.selected === null"
                  class="mb-4"
                  placeholder="Selecione 1 ou mais CREAS"
                  label="CREAS">
                  <template v-slot="{ option }">
                    {{ option }}
                  </template>
                </MultipleChoices>
              </div>
            </div>

            <div class="col-12 cold-md-6 col-lg-8">
              <div class="mb-4" v-if="state.selected">
                <Label text="Estado" />
                <Tag :text="state.selected" @close="resetState" bgColor="bg-blue" />
              </div>
              <div class="mt-4 mb-1" v-if="city.selected">
                <Label text="Município" />
                <Tag :text="city.selected" @close="resetCity" bgColor="bg-blue" />
              </div>
              <div class="mt-4 pt-2" v-if="items.selected.length > 0">
                <Label text="CREAS selecionados" />
                  <div class="equipaments-tag-wrapper">
                    <Tag
                      v-for="(item, i) in items.selected"
                      :key="i"
                      @close="items.selected.splice(i, 1)"
                      :text="item"
                      class="mr-2 mb-2"
                      bgColor="bg-red"/>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <LineSeparator />
        <div class="button-wrapper">
          <Button class="btn-primary ml-2" v-on:click.native="applyFilter" text="Aplicar filtro" :disabled="!canApplyFilter" />
          <Button class="btn-secondary mr-2"  v-on:click.native="resetFilter" text="Limpar filtro" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Options from '../atoms/Options.vue'
import MultipleChoices from '../atoms/MultipleChoices.vue'
import Tag from '../atoms/Tag.vue'
import Label from '../atoms/Label.vue'
import LineSeparator from '../atoms/LineSeparator.vue'
import Button from '../atoms/Button.vue'
import FilterHeaderTitle from '../atoms/FilterHeaderTitle.vue'

export default {
  props: ['cities', 'unities', 'data'],
  computed: {
    canApplyFilter() {
      return this.state.selected && this.city.selected && this.items.selected.length > 0;
    },
  },
  components: {
    Options, Tag, Label, MultipleChoices, LineSeparator, Button, FilterHeaderTitle
  },
  methods: {
    resetCras() {
      this.items.selected = [];
      this.$emit("input", null);
      this.isOpen = true
    },
    resetCity() {
      this.city.selected = null
      this.$emit("input", null);
      this.isOpen = true
    },
    resetState() {
      this.state.selected = null
      this.city.selected = null
      this.$emit("input", null);
      this.isOpen = true
    },
    resetFilter() {
      this.state.selected = null
      this.city.selected = null
      this.items.selected = []
      this.$emit("input", null);
    },
    applyFilter() {
      const filter = {
        state: this.state.selected,
        city: this.city.selected,
        items: this.items.selected,
      }

      this.isOpen = false;
      this.$emit("input", filter);
    }
  },
  data() {
    return {
      isOpen: true,
      state: {
        options: [
          {key: "Santa Catarina", label: "Santa Catarina"}
        ],
        selected: null,
      },
      city: {
        options: this.cities,
        selected: null
      },
      items: {
        options: [],
        selected: []
      },
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../sass/bootstrap";

#headingOne {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#headingOne h4 {
  font-family: $font-muli;
}

#headingOne i {
  font-size: 13px;
}

.accordion .card {
  overflow: initial;
}

.arrow-toggle {
  color: $blue;
}

.equipaments-tag-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.button-wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px;
}

.filter-header {
  display: flex;
  align-items: center;
}
</style>
