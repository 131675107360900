<template>
  <li class="nav-item">
    <slot></slot>
  </li>
</template>

<script>
export default {
  name: 'NavItem'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@import "../../sass/bootstrap";

.nav-item {
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
}
</style>
