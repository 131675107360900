<template>
  <div id="equipament-info" class="card more-info">
    <div @click="close" class="more-info-label-wrapper mb-2">
      <i class="fa fa-chevron-right"></i>
      <p>Voltar</p>
    </div>
    <div v-if="this.equipament['Associação Municipal']" class="mb-3">
      <i class="fa fa-check"></i><span>Validado por {{this.equipament['Associação Municipal']}} </span>
    </div>
    <p class="equipament-type">{{ this.equipament["Equipamento"] }}</p>
    <div class="mb-1">
      <i class="fa fa-map-marker" aria-hidden="true"></i> <span class="info-text">{{ this.equipament["Município"] }}</span>
      <p class="info-text address">{{ this.equipament["Endereço"] }}</p>
    </div>
    <div class="mb-2">
      <i class="fa fa-phone" aria-hidden="true"></i><span class="info-text">{{ this.equipament["Telefone"] }}</span>
    </div>
    <div class="mb-4">
      <i class="fa fa-envelope-o" aria-hidden="true"></i><span class="info-text">{{ this.equipament["E-mail"] }}</span>
    </div>
    <div v-if="this.equipament['Público Atendido / Ação Desenvolvida']" class="mb-4">
      <p class="equipament-type">Público Atendido / Ação Desenvolvida</p>
      <span class="info-text">{{ this.equipament["Público Atendido / Ação Desenvolvida"] }}</span>
    </div>
    <div v-if="this.equipament['Condição de Funcionamento']">
      <p class="equipament-type">Condição de Funcionamento</p>
      <span class="info-text">{{ this.equipament["Condição de Funcionamento"] }}</span>
    </div>
    <div v-if="this.equipament['Bairros Abrangidos']" class="mb-3">
      <p class="equipament-type">Bairros Abrangidos</p>
      <span class="info-text">{{ this.equipament["Bairros Abrangidos"] }}</span>
    </div>
    <div v-if="this.equipament['Situação de Atendimento']" class="mb-3">
      <p class="equipament-type">Situação de Atendimento</p>
      <span class="info-text">{{ this.equipament["Situação de Atendimento"] }}</span>
    </div>
    <div v-if="this.equipament['Novos Contatos na Pandemia']">
      <p class="equipament-type">Outros Contatos na Pandemia</p>
      <span class="info-text">{{ this.equipament["Novos Contatos na Pandemia"] }}</span>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    close() {
      this.$emit('close', true);
    }
  },
  props: ['equipament'],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../sass/bootstrap";

#equipament-info {
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 10px;
  height: calc(100% - 20px);
  max-width: 270px;
}

.more-info-label-wrapper {
  display: flex;
}

.more-info-label-wrapper p {
  font-family: $font-hind;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  margin-left: 5px;

  cursor: pointer;
  color: $blue;
}

.more-info {
  background-color: white;
  padding: 20px;
}

.more-info i {
  color: $blue;
  cursor: pointer;
}

.more-info .open{
  color: #14A38B;
}

.more-info .info-text {
  font-family: $font-hind;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;

  color: $gray-400;
}

.more-info .address {
  margin-left: 18px;
}

.more-info span {
  margin-left: 5px;
}

.equipament-type {
  font-family: $font-hind;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;

  color: $black;
}

</style>
