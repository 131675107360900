<template>
  <div ref="options" class="position-relative">
    <h5 class="input-label">{{ label }}</h5>
    <input
      @blur="blur"
      :placeholder="placeholder"
      @focus="resetState"
      v-on="listeners"
      v-model="filter"
      type="text"
      :disabled=disabled
      class="form-control is-select search-input"/>
    <div v-if="open" class="form-control-list">
      <ul class="list-unstyled">
        <li v-for="option in filtered" :key="option.key">
          <label class="m-0 d-block">
            <input
              v-model="selected"
              :value="option.key"
              name="option"
              type="radio"
              class="form-control-list-input" />
            <span class="form-control-list-item">
              {{ option.label }}
            </span>
          </label>
        </li>
        <li v-if="filtered.length === 0">
          <label class="m-0 d-block">
            <span class="form-control-list-item">
              Nenhum resultado encontrado
            </span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['options', 'value', 'label', 'placeholder', 'disabled'],
  computed: {
    filtered() {
      if (!this.filter) {
        return this.options;
      }

      return this.options.filter(option => option.label.slugify().includes(this.filter.slugify()));
    },
    listeners() {
      const events = {
        input() {
          return null;
        },
      };

      return Object.assign({}, this.$listeners, events);
    },
  },
  methods: {
    resetState() {
      this.open = true
      this.$emit("input", null);
      this.filter = null;
    },
    blur() {
      setTimeout(() => {
        this.open = false;
        this.defineLabel(this.value);
      }, 250);
    },
    defineLabel(value) {
      const item = this.options.find(option => option.key === value);

      if (item) {
        this.filter = item.label;
      } else {
        this.filter = null;
      }
    },
    onClick(event) {
      if (this.$refs.options.contains(event.target)) {
        return;
      }

      this.defineLabel(this.value);

      this.open = false;
    },
  },
  watch: {
    selected(value) {
      this.$emit('input', value);
    },
    value(value) {
      this.selected = value;
      this.defineLabel(value);
    },
  },
  data() {
    return {
      filter: null,
      open: false,
      selected: null,
    };
  },
  mounted() {
    this.selected = this.value;

    window.addEventListener('click', this.onClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClick);
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/bootstrap";

.close-button {
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 14px;
}
.close-button span {
  border: 0;
}

.input-label {
  color: $gray-300;
  font-family: $font-muli;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.search-input {
  font-family: $font-hind;
  border: 0;
  width: 315px;
}

.form-control-list ul {
  background-color: $gray-100;
}

</style>
