import axios from 'axios';

const backend = axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT,
});

const Api = {

  getData: () => backend.get('/data').then(({ data }) => data.data),

  getCities: () => backend.get('/cities').then(({ data }) => data.cities.map(city => ({ key: city, label: city }))),

  getCrasTitle: () => backend.get('/cras_title').then(({ data }) => data.cras_title),

  getCreasTitle: () => backend.get('/creas_title').then(({ data }) => data.creas_title),

  getCrasData: (year) => backend.get('/cras', {
    params: {
      ano: year,
    },
  }).then(({ data }) => data),

  getCreasData: (year) => backend.get('/creas', {
    params: {
      ano: year,
    },
  }).then(({ data }) => data),

};

export default Api;
